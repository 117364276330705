/* Global Styles */
body {
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Fix: contrast ratio */
.text-shadow {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
}

/* Contact Page Styles */
/* Text styles */
.contact-form-heading h2 {
  font-family: "Playfair Display";
  margin-top: 60px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 2rem;
}
.contact-form-heading p {
  margin: 20px auto;
  text-align: center;
  font-family: "Playfair Display";
}
.contact-form-hr {
  margin: auto;
  margin-bottom: 20px;
  max-width: 550px;
  border-top: 1px solid #5363877f;
}
@media only screen and (max-width: 600px) {
  .contact-main-wrapper {
    padding: 10px;
  }
  .contact-form-wrapper {
    padding: 15px;
  }
  .contact-input-flex {
    display: block;
  }
  .contact-input-flex div {
    width: 100%;
    margin-bottom: 20px;
  }
}
.contact-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  margin-bottom: 60px;
}
.contact-form-wrapper {
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
  background: white;
}
.contact-input-flex {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}
.contact-input-flex > div {
  width: 50%;
}
.contact-form-input {
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  outline: none;
  resize: none;
  box-sizing: border-box;
}
.contact-form-input::placeholder {
  color: #536387;
  font-family: "Playfair Display";
}
input.contact-form-input,
textarea.contact-form-input {
  font-family: "Playfair Display";
}
.contact-form-input:focus {
  border-color: #c85933;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.contact-form-label {
  color: #07074d;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
.contact-btn {
  text-align: center;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  padding: 14px 25px;
  border: none;
  font-weight: 500;
  background-color: #c85933;
  color: white;
  cursor: pointer;
  margin-top: 25px;
}
.contact-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
/* Error/Success Message Styling */
.error-message {
  color: red;
}
.message-container {
  width: 100%;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
}
.error-message,
.success-message {
  font-size: 1.1rem;
}
.success-message {
  color: black;
  font-weight: bold;
}
/* Update Busca Form Colors */
.busca .contact-form-input:focus {
  border-color: #285ff6;
}
.busca .contact-btn {
  background-color: #343459;
}
/* Styles to Loans Page */
.loans-form-image {
  height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.loans-form-image {
  height: 450px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.loans-form-hr {
  margin-bottom: 40px;
  border-top: 1px solid #5363877f;
}
.loans-mb-3 {
  margin-bottom: 15px;
}
.loans-relative {
  position: relative;
}
.loans-opacity-0 {
  opacity: 0;
}
.loans-stroke-current {
  stroke: currentColor;
}
#supportCheckbox:checked ~ div span {
  opacity: 1;
}
.loans-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  padding-top: 0px;
}
.loans-form-wrapper {
  margin: 0 auto;
  max-width: 570px;
  width: 100%;
  background: white;
  padding: 40px;
}
.loans-img {
  margin-bottom: 45px;
}
.loans-form-title {
  margin-bottom: 30px;
}
.loans-form-title h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #c85933;
}
.loans-form-title p {
  font-size: 16px;
  line-height: 24px;
  color: #536387;
  margin-top: 12px;
}
.loans-input-flex {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}
.loans-input-flex > div {
  width: 50%;
}
.loans-form-input {
  text-align: center;
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
  box-sizing: border-box;
}
.loans-form-input:focus {
  border-color: #c85933;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.loans-form-label {
  color: #536387;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}
.loans-btn {
  font-size: 16px;
  border-radius: 5px;
  padding: 14px 25px;
  border: none;
  font-weight: 500;
  background-color: #c85933;
  color: white;
  cursor: pointer;
  margin-top: 25px;
}
.loans-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 600px) {
  .loans-main-wrapper {
    padding: 10px;
  }
  .loans-form-wrapper {
    padding: 15px;
  }
  .loans-input-flex {
    display: block;
  }
  .loans-input-flex div {
    width: 100%;
    margin-bottom: 20px;
  }
  .loans-form-image {
    height: 300px;
  }
}
.busca .loans-form-title h2 {
  color: #07074d;
}
.busca .loans-form-input:focus {
  border-color: #285ff6;
}
.busca .loans-btn {
  background-color: #343459;
}
/* Pricing Page style */
/* Styles to Agent Signup Pricing Page */
.pricing-main {
  padding: 30px;
}
/* Mobile/Desktop Display */
.pricing-desktop {
  display: block;
}
.pricing-mobile {
  display: none;
}
@media only screen and (max-width: 900px) {
  .pricing-desktop {
    display: none;
  }
  .pricing-mobile {
    display: block;
  }
}
.pricing-container {
  background-color: #f5f7fc66;
  border-radius: 8px;
  box-shadow: 0px 4px 15px -1px #11085034;
  padding: 10px 20px 20px;
}
/* Pricing Header */
.pricing-header {
  margin: auto;
  margin-top: 90px;
  text-align: center;
  width: 70%;
}
.pricing-header h1 {
  color: #343459;
  font-size: 1.8rem;
  margin-bottom: 40px;
}
.pricing-header h2 {
  color: rgb(22, 23, 39);
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 20px;
}
.pricing-header p {
  color: rgb(22, 23, 39);
}
/* Styles to Desktop Pricing Table */
.pricing-container table {
  border: none;
}
.pricing-th h2 {
  font-size: 2rem;
  margin-bottom: 5px;
}
.pricing-th h2,
.pricing-price {
  color: #343459;
}
.pricing-price {
  font-size: 1.2rem;
  margin-right: 10px;
}
.pricing-conditions {
  color: rgba(62, 40, 40, 0.75);
  font-weight: 300;
}
.pricing-description {
  height: 70px;
  color: rgba(40, 43, 62, 0.78);
}
.pricing-th div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding-left: 10px;
  text-align: left;
  width: 100%;
  margin: auto;
}
.pricing-th hr {
  border: 0.5px solid rgba(40, 43, 62, 0.392);
  width: 100%;
}
.pricing-container tbody tr:hover {
  background-color: #f2f2f2;
}
.pricing-container tbody td {
  width: 20%;
}
.pricing-container td,
.pricing-container th {
  padding: 20px;
}
@media only screen and (max-width: 1200px) {
  .pricing-th div {
    height: 80px;
    justify-content: center;
    padding-left: 0px;
  }
  .pricing-price {
    font-size: 1rem;
    margin-right: 5px;
    width: auto;
  }
}
.check-container {
  display: flex;
  justify-content: center;
}
.pricing-container tbody img {
  width: 30px;
}
.pricing-signup-btn {
  border: 1px solid #343459;
  border-radius: 10px;
  background-color: #343459;
  color: white;
  font-weight: bold;
  padding: 10px 15px;
  width: 100%;
}
.pricing-signup-btn:hover {
  cursor: pointer;
}
.pricing-container tbody .no-hover:hover {
  background-color: #f5f7fc66;
}
.pricing-container {
  position: relative;
}
.pricing-banner {
  position: absolute;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: #343459;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  left: 0px;
  top: 70px;
  padding: 10px 20px;
}
@media only screen and (max-width: 1000px) {
  .pricing-banner {
    font-size: 1.1rem;
  }
}
/* Pricing Footer */
.pricing-footer {
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;
}
/* Styles to Mobile Pricing Cards */
.pricing-signup-btn.mobile {
  margin-top: 20px;
}
.pricing-th.mobile h2 {
  margin-bottom: 5px;
}
.pricing-th .mobile-div {
  height: 50px;
}
.pricing-description.mobile {
  height: auto;
  margin-top: 30px;
}
.pricing-mobile .pricing-container {
  margin-top: 30px;
}
/* Styles to Pricing Modal */
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.236);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  width: 50%;
}
.modal-close-btn {
  background-color: #fff;
  border: 1px solid #343459;
  border-radius: 5px;
  color: #343459;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 5px 10px !important;
}
.modal-close-btn:hover {
  cursor: pointer;
}
.pricing-modal h3 {
  font-size: 1.8rem;
  margin-top: 0;
  padding-top: 45px;
}
.pricing-modal form {
  margin: auto;
  margin-bottom: 50px;
  width: 80%;
}
.pricing-modal label {
  color: rgba(13, 23, 43, 0.892);
  display: block;
  margin-top: 20px;
  margin-bottom: 5px;
}
.pricing-modal span {
  color: red;
}
.pricing-modal input {
  border: 1px solid rgba(128, 128, 128, 0.449);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
}
.submit-btn {
  background-color: #343459;
  color: #fff;
  font-weight: bold;
  margin-top: 30px;
}
.submit-btn:hover {
  cursor: pointer;
}
@media only screen and (max-width: 650px) {
  .modal-container {
    width: 95%;
  }
}
.modal-container .success-message {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.success-close-btn {
  border: 1px solid #343459;
  border-radius: 10px;
  background-color: #343459;
  color: #fff;
  font-weight: bold;
  margin: auto;
  margin-top: 30px;
  padding: 10px 0px;
  width: 80%;
}
.success-close-btn:hover {
  cursor: pointer;
}

/* Agent Profile Styles */
.breadcrumb {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #53638707;
  font-family: "Playfair Display";
}
.breadcrumb .text {
  margin-left: 20px;
}
.breadcrumb .back-button {
  margin-left: 5px;
  color: #0133a4;
  text-decoration: underline;
}
.breadcrumb .back-button:hover {
  cursor: pointer;
}
.agent-profile-page {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}
.agent-col-1 {
  width: 340px;
  padding: 10px;
  margin-bottom: 30px;
}
.agent-col-1 h1 {
  margin: 0;
  margin-top: 40px;
  font-family: "Playfair Display";
}
.agent-col-1 p {
  font-family: "Open Sans";
  margin: 0;
}
.agent-photo {
  border-radius: 10px;
  object-fit: cover;
  margin-top: 30px;
  width: 300px;
  height: 300px;
}
.with-border {
  box-shadow: 0px -5px 0px #c85933;
}
.agent-col-1 h2 {
  margin: 0;
  margin-bottom: 7px;
  font-family: "Playfair Display";
  font-size: 20px;
}
.agent-info {
  margin-top: 30px;
}
.agent-col-2 {
  padding: 30px;
  color: #536387;
  font-size: 16px;
  font-family: "Open Sans";
  margin-top: 60px;
  line-height: 25px;
  min-width: calc(100% - 400px);
}
.agent-profile-page .flex {
  display: flex;
  align-items: center;
}
.agent-profile-page .icon {
  width: 20px;
  margin-right: 10px;
}
@media only screen and (max-width: 700px) {
  .agent-profile-page {
    flex-wrap: wrap;
  }
  .agent-col-1 {
    width: 100%;
    margin-bottom: 0;
  }
  .agent-col-1 h1,
  .agent-col-1 h2,
  .agent-col-1 p {
    text-align: center;
  }
  .agent-photo {
    display: block;
    margin: auto;
    margin-top: 3%;
  }
  .agent-col-2 {
    margin-top: 20px;
    margin-bottom: 50px;
    min-width: auto;
  }
  .agent-profile-page .flex {
    width: 100%;
    justify-content: center;
  }
}
@media only screen and (max-width: 400px) {
  .breadcrumb {
    font-size: 12px;
  }
}
.min-height {
  min-height: 300px;
}
.edit-btn-container {
  display: flex;
  justify-content: right;
  max-width: 1000px;
  margin: auto;
  padding-right: 30px;
  padding-top: 30px;
}
.edit-agent-profile-btn {
  cursor: pointer;
  color: white;
  background-color: #c85933;
  font-family: "Open Sans";
  font-weight: bold;
  border-radius: 0.5em;
  border: none;
  font-size: 12px;
  text-decoration: none;
  padding: 1.2em 2em;
}
.busca .with-border {
  box-shadow: 0px -5px 0px #343459;
}
.busca .edit-agent-profile-btn {
  background-color: #343459;
}
/* Styles to Agent Signup Form */
.busca h2 {
  color: #343459;
}
.busca .loans-btn {
  background-color: #343459;
}
.password-span {
  color: #343459;
}
.agent-signup-form input {
  text-align: left !important;
}
.loans-form-title.agent-signup {
  text-align: center;
}
.loans-form-title.agent-signup h2 {
  margin: 0;
}
.loans-form-title.agent-signup p {
  margin-top: 10px;
  font-size: 20px;
}
.agent-signup-textarea {
  text-align: left;
  height: 200px;
}
.agent-signup-photo-upload {
  display: flex;
  justify-content: space-between;
  color: #536387;
}
.areas-btn {
  cursor: pointer;
  padding: 12px 20px;
  color: #536387;
  border: 1px solid #dde3ec;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 35px;
  width: 100%;
}
.areas-text {
  color: #536387;
}
.area-btn {
  display: inline-block;
  padding: 5px 10px;
  color: #536387;
  border: 1px solid #dde3ec;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 20px;
}
.area-btn span {
  cursor: pointer;
  color: #536387ae;
  margin-left: 10px;
}
.character-count {
  color: #536387;
  text-align: right;
}
.character-count.error {
  color: red;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.password-span {
  position: absolute;
  margin-left: -50px;
  margin-top: 13px;
}
.password-span:hover {
  cursor: pointer;
}
.agent-form-select {
  width: 100%;
  padding: 12px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
}
.agent-form-select::placeholder {
  color: #536387;
  font-family: "Playfair Display";
}
/* Styles to Agent Portal*/
.agent-portal {
  background-color: #dadce2b7;
  min-height: 100vh;
}
.agent-portal header {
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 80px 10px 40px;
  background-color: #f5f5f5;
}
.agent-portal-logo {
  height: 80px;
}
.agent-portal header div:hover {
  cursor: pointer;
}
.agent-portal header p:after {
  content: "\276F";
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: 5px;
  position: absolute;
  transform-origin: center;
  transform: rotateZ(90deg);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgb(54, 54, 54);
}
.nav-open p:after {
  transform: rotateZ(270deg) !important;
  margin-top: -7px !important;
}
.agent-portal-menu {
  display: none;
  position: absolute;
  background-color: #f5f5f5;
}
.agent-portal-menu ul {
  padding: 0;
}
.busca .agent-portal-menu ul li {
  padding: 30px;
  border: 1px solid #343459;
}
.busca .agent-portal-menu ul li:hover {
  color: #343459;
}
.agent-portal-menu.open {
  display: block;
  border: 1px solid #343459;
  padding: 10px;
}
/* Dashboard Styles */
.agent-search-result-card.ap-card {
  width: 275px;
  background-color: #f5f5f5;
  margin-top: 20px;
}
.ap-dashboard {
  max-width: 900px;
  margin: auto;
  padding: 30px 70px;
}
.ap-dashboard main {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
}
.ap-account-details {
  margin-bottom: 5px;
  width: 100%;
}
.ap-account-details img {
  height: 20px;
  position: absolute;
  margin-left: 10px;
}
.ap-account-details img:hover {
  cursor: pointer;
}
.ap-card-conatiner {
  position: relative;
  display: flex;
  justify-content: center;
}
.agent-portal .ap-dashboard h1 {
  color: rgb(57, 57, 57);
  font-size: 1.7rem !important;
}
.muted {
  position: absolute;
  background-color: #ffffffbc;
  border-radius: 10px;
  height: 400px;
  width: 275px;
  z-index: 9999;
  top: 0;
}
.ap-details-input {
  margin: auto;
  text-align: left;
  width: 270px;
}
.ap-details-btns {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.ap-details-btns button {
  width: 40%;
  background-color: gray;
  font-family: "Open Sans";
  color: white;
  font-weight: bold;
  border-radius: 0.5em;
  border: none;
  font-size: 12px;
  text-decoration: none;
  padding: 1.2em 2em;
}
.ap-details-btns button:hover {
  cursor: pointer;
}
.busca .ap-details-btns .primary {
  background-color: #343459;
}
.ap-card-alert {
  max-width: 350px;
  color: rgb(110, 110, 110);
}
.busca .ap-edit-profile .update-agent {
  background-color: #343459;
  border: none;
  border-radius: 10px;
  color: #fff;
  padding: 1.5rem 0.5rem;
}
.ap-edit-profile h1 {
  text-align: center;
}
.ap-edit-profile .update-agent:hover {
  cursor: pointer;
}
.ap-edit-profile select {
  width: 100%;
  padding: 12px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
}
.ap-edit-profile select::placeholder {
  color: #536387;
  font-family: "Playfair Display";
}
.ap-dashboard .agent-search-result-card h3 {
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .agent-col-2.edit-profile-page {
    min-width: 80% !important;
  }
  .ap-edit-profile h1 {
    margin-top: 30px;
  }
}
.agent-portal.busca.hidden {
  display: none;
}
/* Lender Profile Styles */
.breadcrumb {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #53638707;
  font-family: "Playfair Display";
}
.breadcrumb .text {
  margin-left: 20px;
}
.breadcrumb .back-button {
  margin-left: 5px;
  color: #0133a4;
  text-decoration: underline;
}
.breadcrumb .back-button:hover {
  cursor: pointer;
}
.agent-profile-page {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}
.agent-col-1 {
  width: 340px;
  padding: 10px;
  margin-bottom: 30px;
}
.agent-col-1 h1 {
  margin: 0;
  margin-top: 40px;
  font-family: "Playfair Display";
}
.agent-col-1 p {
  font-family: "Open Sans";
  margin: 0;
}
.agent-photo {
  border-radius: 10px;
  object-fit: cover;
  margin-top: 30px;
  width: 300px;
  height: 300px;
}
.with-border {
  box-shadow: 0px -5px 0px #c85933;
}
.agent-col-1 h2 {
  margin: 0;
  margin-bottom: 7px;
  font-family: "Playfair Display";
  font-size: 20px;
}
.agent-info {
  margin-top: 30px;
}
.agent-col-2 {
  padding: 30px;
  color: #536387;
  font-size: 16px;
  font-family: "Open Sans";
  margin-top: 60px;
  line-height: 25px;
  min-width: calc(100% - 400px);
}
.agent-profile-page .flex {
  display: flex;
  align-items: center;
}
.agent-profile-page .icon {
  width: 20px;
  margin-right: 10px;
}
@media only screen and (max-width: 700px) {
  .agent-profile-page {
    flex-wrap: wrap;
  }
  .agent-col-1 {
    width: 100%;
    margin-bottom: 0;
  }
  .agent-col-1 h1,
  .agent-col-1 h2,
  .agent-col-1 p {
    text-align: center;
  }
  .agent-photo {
    display: block;
    margin: auto;
    margin-top: 3%;
  }
  .agent-col-2 {
    margin-top: 20px;
    margin-bottom: 50px;
    min-width: auto;
  }
  .agent-profile-page .flex {
    width: 100%;
    justify-content: center;
  }
}
@media only screen and (max-width: 400px) {
  .breadcrumb {
    font-size: 12px;
  }
}
.min-height {
  min-height: 300px;
}
.edit-btn-container {
  display: flex;
  justify-content: right;
  max-width: 1000px;
  margin: auto;
  padding-right: 30px;
  padding-top: 30px;
}
.edit-agent-profile-btn {
  cursor: pointer;
  color: white;
  background-color: #c85933;
  font-family: "Open Sans";
  font-weight: bold;
  border-radius: 0.5em;
  border: none;
  font-size: 12px;
  text-decoration: none;
  padding: 1.2em 2em;
}
.busca .with-border {
  box-shadow: 0px -5px 0px #343459;
}
.busca .edit-agent-profile-btn {
  background-color: #343459;
}
/* Lender Signup Styles */
.busca h2 {
  color: #343459;
}
.busca .loans-btn {
  background-color: #343459;
}
.password-span {
  color: #343459;
}
.agent-signup-form input {
  text-align: left !important;
}
.loans-form-title.agent-signup {
  text-align: center;
}
.loans-form-title.agent-signup h2 {
  margin: 0;
}
.loans-form-title.agent-signup p {
  margin-top: 10px;
  font-size: 20px;
}
.agent-signup-textarea {
  text-align: left;
  height: 200px;
}
.agent-signup-photo-upload {
  display: flex;
  justify-content: space-between;
  color: #536387;
}
.areas-btn {
  cursor: pointer;
  padding: 12px 20px;
  color: #536387;
  border: 1px solid #dde3ec;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 35px;
  width: 100%;
}
.areas-text {
  color: #536387;
}
.area-btn {
  display: inline-block;
  padding: 5px 10px;
  color: #536387;
  border: 1px solid #dde3ec;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 20px;
}
.area-btn span {
  cursor: pointer;
  color: #536387ae;
  margin-left: 10px;
}
.character-count {
  color: #536387;
  text-align: right;
}
.character-count.error {
  color: red;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.password-span {
  position: absolute;
  margin-left: -50px;
  margin-top: 13px;
}
.password-span:hover {
  cursor: pointer;
}
.agent-form-select {
  width: 100%;
  padding: 12px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
}
.agent-form-select::placeholder {
  color: #536387;
  font-family: "Playfair Display";
}
/* Lender Portal Styles */
.agent-portal {
  background-color: #dadce2b7;
  min-height: 100vh;
}
.agent-portal header {
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 80px 10px 40px;
  background-color: #f5f5f5;
}
.agent-portal-logo {
  height: 80px;
}
.agent-portal header div:hover {
  cursor: pointer;
}
.agent-portal header p:after {
  content: "\276F";
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: 5px;
  position: absolute;
  transform-origin: center;
  transform: rotateZ(90deg);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgb(54, 54, 54);
}
.nav-open p:after {
  transform: rotateZ(270deg) !important;
  margin-top: -7px !important;
}
.agent-portal-menu {
  display: none;
  position: absolute;
  background-color: #f5f5f5;
}
.agent-portal-menu ul {
  padding: 0;
}
.busca .agent-portal-menu ul li {
  padding: 30px;
  border: 1px solid #343459;
}
.busca .agent-portal-menu ul li:hover {
  color: #343459;
}
.agent-portal-menu.open {
  display: block;
}
/* Dashboard Styles */
.agent-search-result-card.ap-card {
  width: 275px;
  background-color: #f5f5f5;
  margin-top: 20px;
}
.ap-dashboard {
  max-width: 900px;
  margin: auto;
  padding: 30px 70px;
}
.ap-dashboard main {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
}
.ap-account-details {
  margin-bottom: 5px;
  width: 100%;
}
.ap-account-details img {
  height: 20px;
  position: absolute;
  margin-left: 10px;
}
.ap-account-details img:hover {
  cursor: pointer;
}
.ap-card-conatiner {
  position: relative;
  display: flex;
  justify-content: center;
}
.agent-portal .ap-dashboard h1 {
  color: rgb(57, 57, 57);
  font-size: 1.7rem !important;
}
.muted {
  position: absolute;
  background-color: #ffffffbc;
  border-radius: 10px;
  height: 400px;
  width: 275px;
  z-index: 9999;
  top: 0;
}
.ap-details-input {
  margin: auto;
  text-align: left;
  width: 270px;
}
.ap-details-btns {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.ap-details-btns button {
  width: 40%;
  background-color: gray;
  font-family: "Open Sans";
  color: white;
  font-weight: bold;
  border-radius: 0.5em;
  border: none;
  font-size: 12px;
  text-decoration: none;
  padding: 1.2em 2em;
}
.ap-details-btns button:hover {
  cursor: pointer;
}
.busca .ap-details-btns .primary {
  background-color: #343459;
}
.ap-card-alert {
  max-width: 350px;
  color: rgb(110, 110, 110);
}
.busca .ap-edit-profile .update-agent {
  background-color: #343459;
  border: none;
  border-radius: 10px;
  color: #fff;
  padding: 1.5rem 0.5rem;
}
.ap-edit-profile h1 {
  text-align: center;
}
.ap-edit-profile .update-agent:hover {
  cursor: pointer;
}
.ap-edit-profile select {
  width: 100%;
  padding: 12px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
}
.ap-edit-profile select::placeholder {
  color: #536387;
  font-family: "Playfair Display";
}
.ap-dashboard .agent-search-result-card h3 {
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .agent-col-2.edit-profile-page {
    min-width: 80% !important;
  }
  .ap-edit-profile h1 {
    margin-top: 30px;
  }
}
.agent-portal.busca.hidden {
  display: none;
}
.busca .with-border {
  box-shadow: 0 -5px 0 #343459;
}
.busca .agent-card-top,
.busca .agent-card-btn {
  background-color: #343459;
}
/* Styles to Admin Page */
.justify-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.btn-purple {
  background-color: #343459;
  border: none;
  border-radius: 0.5em;
  color: #fff;
  cursor: pointer;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  margin: 0.5em auto auto;
  padding: 1em 2em;
  text-decoration: none;
}
.btn-primary {
  background-color: #c85933;
  border: none;
  border-radius: 0.5em;
  color: #fff;
  cursor: pointer;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  margin: 0.5em auto auto;
  padding: 1em 2em;
  text-decoration: none;
}
