.agent-search-image {
  height: 400px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.agent-search-header {
  position: relative;
}
.agent-search-header-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 5px;
  background-color: #00000050;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.agent-search-header-bg h1 {
  color: #fff;
  text-shadow: 0px 0px 12.6px #00000075;
  font-size: 3rem;
  margin-right: 60px;
}
.agent-search-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.agent-search-btn {
  cursor: pointer;
  color: white;
  background-color: #c85933;
  font-family: "Open Sans";
  font-weight: bold;
  border-radius: 0.5em;
  border: none;
  font-size: 12px;
  text-decoration: none;
  padding: 1em 2em;
}
.hidden {
  display: none;
}
.input-container {
  position: relative;
}
.agent-search-input {
  width: 250px;
  padding: 10px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 14px;
  color: #212121;
  outline: none;
  resize: none;
  box-sizing: border-box;
  margin-right: 10px;
}
.agent-search-input::placeholder {
  color: #536387;
  font-family: "Playfair Display";
}
input.agent-search-input,
textarea.agent-search-input {
  font-family: "Playfair Display";
}
.agent-search-input:focus {
  border-color: #c85933;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 700px) {
  .agent-search-header-bg {
    padding: 20px;
  }
  .agent-search-header-bg h1 {
    font-size: 2.5rem;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .agent-search-form {
    flex-wrap: wrap;
  }
  .input-container,
  .agent-search-input {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
  }
}
.agent-search-results {
  max-width: 900px;
  margin: auto;
  margin-bottom: 70px;

  padding: 20px;
}
.agent-search-results h2 {
  text-align: center;
  font-weight: 400;
  font-size: 1.3rem;
}
.agent-search-results-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  flex-wrap: wrap;
}
/* Styles to Pagination */
.pagination-container::before,
.pagination-container::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
.pagination-container .pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.6rem;
  background: #ffffff;
  box-shadow: 0 0.8rem 2rem rgba(#5a6181, 0.05);
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-y: auto;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.6px;
  line-height: 1.4;
  -webkit-user-select: none;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  list-style-type: none;
}
.pagination-container .page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  font-size: 1.4rem;
  cursor: pointer;
  margin-top: 1rem;
  /* color: #c85933e1; */
}
.pagination-container .page-item {
  min-width: 40px;
  height: 40px;
  border-radius: 0.4rem;
}
.pagination-container .page-item:hover {
  color: #c85933;
}
.pagination-container .page-item.active {
  color: #ffffff;
  background: #c85933;
  font-weight: 600;
  border: 1px solid #c85933;
}

/* Dropdown Styles */
.dropdown-items {
  position: absolute;
  z-index: 4;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.dropdown-item {
  width: 250px;
  padding: 0px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  color: #07074d;
  outline: none;
  resize: none;
  box-sizing: border-box;
  margin-right: 10px;
  font-family: "Playfair Display";
}
.dropdown-item:hover {
  border: 1px solid #c85933;
  cursor: pointer;
}
.dropdown-item.highlight {
  border: 1px solid #c85933;
}
@media only screen and (max-width: 700px) {
  .dropdown-items {
    box-sizing: border-box;
    margin-top: -20px;
    width: 100%;
  }
  .dropdown-item {
    width: 100%;
    padding: 10px 22px;
  }
}

.busca .agent-search-btn {
  background-color: #343459;
}
.busca .agent-search-input:focus {
  border-color: #343459;
}
.busca.pagination-container .page-item:hover {
  color: #343459;
}
.busca.pagination-container .page-item.active {
  background: #343459;
  border: 1px solid #343459;
}
/* Dropdown Styles */
.busca .dropdown-item:hover {
  border: 1px solid #343459;
}
.busca .dropdown-item.highlight {
  border: 1px solid #343459;
}
/* Lender Search Page */
.lender-search-header-bg {
  background-color: #000000a3;
  justify-content: left;
  padding-left: 100px;
}
@media only screen and (max-width: 900px) {
  .lender-search-header-bg {
    padding-left: 20px;
  }
}
.lender-search-form {
  justify-content: left;
}
