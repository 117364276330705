.signup-success-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  max-width: 700px;
  margin: auto;
}
.signup-success-page h1 {
  margin-top: 70px;
  margin-bottom: 20px;
}
.signup-success-page h1,
.signup-success-page p {
  /* font-family: "Playfair Display"; */
  width: 100%;
  text-align: center;
  color: rgb(68, 68, 68);
}
.signup-success-page h2 {
  font-weight: 400;
}
.signup-success-page a {
  margin-top: 20px;
  margin-bottom: 70px;
}
.signup-success-page a,
.signup-success-page button {
  cursor: pointer;
}
.signup-success-page button {
  cursor: pointer;
  color: white;
  background-color: #c85933;
  font-family: "Open Sans";
  font-weight: bold;
  border-radius: 0.5em;
  border: none;
  font-size: 16px;
  text-decoration: none;
  padding: 1em 2em;
  margin-top: 20px;
}
.signup-success-page .link {
  color: #c85933;
  font-weight: bold;
  text-decoration: none;
}
.busca.signup-success-page button {
  background-color: #343459;
}
.busca.signup-success-page a {
  color: #343459;
}
